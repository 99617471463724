import React from "react"
import { useStaticQuery,StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import CardPagina from "../components/cardPagina"
import {
  Container,
  CardColumns,
  Card,
  Carousel,
  Col,
  Row,
} from "react-bootstrap"

class Gallery extends React.Component {
  
  constructor(props) {
    super(props);
    const images = this.props.data.gallery.edges
    console.log(images)
    this.state = {
      items: images,
      visible: 10,
      error: false,
    }

    this.loadMore = this.loadMore.bind(this)
  }

  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 5 }
    })
  }

  componentDidMount() {
    
  }
  

  render() {
    return (
      <Layout isPagina={true}>
        <SEO
          title="Le nostre realizzazioni a Roma, Anzio, Nettuno, Ardea, Aprilia..."
          description="Galleria Immagini - Tutte le realizzazioni e gli impianti fotovoltaici di NonsoloEnergia effettuate nel Lazio "
        />
        <BanneronePage title="Gallery" />
        <Container className="mt-4 mb-4 text-center">
          <div className="container">
            <h1>Le realizzazioni di NonsoloEnergia SRL</h1>
          </div>
          <CardColumns>
            {this.state.items
              .slice(0, this.state.visible)
              .map((image, index) => {
                return (
                  <Card>
                    <Card.Img
                      src={image.node.childImageSharp.fluid.originalImg}
                      alt={image.node.name.split("-").join(" ")}
                    />
                  </Card>
                )
              })}
          </CardColumns>
          {this.state.visible < this.state.items.length && (
            <button
              onClick={this.loadMore}
              type="button"
              className="loadMore"
            >
              Carica Altre
            </button>
          )}
          <CardPagina className="gridParity">
            Impianti fotovoltaici con e senza batterie "chiavi in mano"
            Anzio, Nettuno, Aprilia, Lanuvio, Ardea, Pomezia, Cisterna di
            Latina, Fiumicino, Sabaudia, Latina, Roma, Frosinone, Viterbo,
            Rieti....Lazio
          </CardPagina>
        </Container>
      </Layout>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
       query {
         gallery: allFile(
           filter: {
             extension: { regex: "/(jpg)|(jpeg)|(png)/" }
             relativeDirectory: { regex: "gallery/immagini/" }
           }
         ) {
           edges {
             node {
               name
               childImageSharp {
                 fluid(maxWidth: 800) {
                   aspectRatio
                   src
                   srcSet
                   originalImg
                 }
               }
             }
           }
         }
       }
     `}

    render={data => <Gallery data={data} {...props} />}
    />
)
